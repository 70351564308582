import React from "react";
import {Box,Grid,MenuItem,Select,FormControl,InputLabel,TextField,CircularProgress,} from "@mui/material";

const AddTenantPaymentForm = ({
  tenantOptions,
  rentalUnitOptions,
  monthOptions,
  isLoadingMonthsAndYears,
  newPayment,
  handleInputChange,
}) => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        {/* Row 1 */}
        <Grid item xs={6}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel>Related Rental Unit</InputLabel>
            <Select
              label="Related Rental Unit"
              name="related_rental_unit"
              value={newPayment.related_rental_unit }
              onChange={handleInputChange}
            >
              {rentalUnitOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel>Related Tenant</InputLabel>
            <Select
              label="Related Tenant"
              name="related_tenant"
              value={newPayment.related_tenant }
              onChange={handleInputChange}
            >
              {tenantOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Row 2 */}
        <Grid item xs={6}>
          <TextField
            label="Amount"
            type="number"
            name="amount"
            value={newPayment.amount }
            onChange={handleInputChange}
            variant="outlined"
            fullWidth
          />
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id="related-month-label">Related Month</InputLabel>
            {isLoadingMonthsAndYears ? (
              <CircularProgress size={24} sx={{ ml: 2 }} />
            ) : (
              <Select
                labelId="related-month-label"
                name="related_month"
                value={newPayment.related_month }
                onChange={handleInputChange}
              >
                {monthOptions.length > 0 ? (
                  monthOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="">No Months Available</MenuItem>
                )}
              </Select>
            )}
          </FormControl>
        </Grid>

        {/* Row 3 */}
        <Grid item xs={6}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel>Payment Method</InputLabel>
            <Select
              label="Payment Method"
              name="payment_method"
              value={newPayment.payment_method }
              onChange={handleInputChange}
            >
              <MenuItem value={1}>Cash</MenuItem>
              <MenuItem value={2}>Mobile Money</MenuItem>
              <MenuItem value={3}>Momo Pay</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <TextField
            label="Date Paid"
            type="date"
            name="date_paid"
            value={newPayment.date_paid }
            onChange={handleInputChange}
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            fullWidth
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddTenantPaymentForm;
