import React from "react";
import axios from "axios";
import { baseURL } from "services/API";
import { useSelector, useDispatch } from "react-redux";
import { Box, Button, Snackbar, MenuItem, Select, FormControl, InputLabel, Typography } from "@mui/material";
import { useLandlordExpenses } from "services/hooks/rentals";
import { setRefreshCount } from "state";
import ExpensesTable from "components/Tables/ExpensesTable";
import ExpenseForm from "components/Forms/LandlordExpensesForm";
import FormDialog from "components/modal";

export default function LandlordExpenses() {
  const dispatch = useDispatch();
  const { landlordExpenses, isLoadingLandlordExpenses, errorLandlordExpenses } = useLandlordExpenses();

  const [open, setOpen] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState(null);
  const [formData, setFormData] = React.useState({
    related_landlord_rental: "",
    amount: "",
    expense_category: "",
    description: ""
  });
  const [openSnack, setOpenSnack] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const [severity, setSeverity] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [currentPage, setCurrentPage] = React.useState(1);
  const [itemsPerPage] = React.useState(10);
  const [filterText, setFilterText] = React.useState("");
  const [filterType, setFilterType] = React.useState("expenseName");
  const [isSubmittingData, setIsSubmittingData] = React.useState(false);

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleMenuClick = (action, item) => {
    setSelectedItem(item);
    setFormData({
      related_landlord_rental: "",
      amount: "",
      expense_category: "",
      description: ""
    });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedItem(null);
    setFormData({
      related_landlord_rental: "",
      amount: "",
      expense_category: "",
      description: ""
    });
  };

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleRentalChange = (event) => {
    setFormData({ ...formData, related_landlord_rental: event.target.value });
  };

  const handleSubmit = () => {
    if (!formData.related_landlord_rental) {
      setOpenSnack({ open: true, vertical: "top", horizontal: "center" });
      setSeverity("error");
      setMessage("Related landlord rental is required.");
      return;
    }

    setIsSubmittingData(true);
    const request = axios.post(`${baseURL}/admin/landlord_expenses`, formData);

    request
      .then(() => {
        setOpenSnack({ open: true, vertical: "top", horizontal: "center" });
        setSeverity("success");
        setMessage(selectedItem ? "Expense updated successfully" : "Expense added successfully");
        dispatch(setRefreshCount());
      })
      .catch((error) => {
        console.error("Error processing expense:", error);
        setOpenSnack({ open: true, vertical: "top", horizontal: "center" });
        setSeverity("error");
        setMessage(selectedItem ? "Error updating expense" : "Error adding expense");
      })
      .finally(() => setIsSubmittingData(false));

    handleClose();
  };

  const filteredData = landlordExpenses.filter((item) => {
    const valueToFilter = item[filterType] || "";
    return valueToFilter.toLowerCase().includes(filterText.toLowerCase());
  });

  const paginatedData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <Box m="20px">
      <Typography
        variant="h4"
        gutterBottom
        sx={{ fontWeight: "bold", mb: 5, fontSize: 25 }}
      >
        Landlord's Expenses
      </Typography>
      <Box
        sx={{ mb: 2, display: "flex", gap: 2, alignItems: "center" }}
      >
        <FormControl variant="outlined" sx={{ minWidth: 120 }}>
          <InputLabel>Filter By</InputLabel>
          <Select
            value={filterType}
            onChange={(event) => setFilterType(event.target.value)}
            label="Filter By"
          >
            <MenuItem value="expenseName">Expense Name</MenuItem>
            <MenuItem value="date">Date</MenuItem>
            <MenuItem value="amount">Amount</MenuItem>
          </Select>
        </FormControl>
        <Box sx={{ marginLeft: 'auto' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleMenuClick(null, null)}
          >
            Add Expense
          </Button>
        </Box>
      </Box>
      <ExpensesTable
        data={paginatedData}
        handleMenuClick={handleMenuClick}
        page={currentPage}
        rowsPerPage={itemsPerPage}
        onPageChange={handlePageChange}
      />
      <Snackbar
        open={openSnack.open}
        autoHideDuration={6000}
        onClose={() => setOpenSnack({ ...openSnack, open: false })}
        message={message}
        action={
          <Button color="inherit" onClick={() => setOpenSnack({ ...openSnack, open: false })}>
            Close
          </Button>
        }
      />
      <FormDialog
        open={open}
        title={selectedItem ? "Edit Expense" : "Add Expense"}
        text="Fill in the details for the expense"
        content={
          <ExpenseForm
            formData={formData}
            handleFormChange={handleFormChange}
            handleRentalChange={handleRentalChange}
          />
        }
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        isSubmittingData={isSubmittingData}
      />
    </Box>
  );
}
