import { useState } from "react";
import { useSelector } from "react-redux";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import SettingsIcon from "@mui/icons-material/Settings";
import ApartmentIcon from "@mui/icons-material/Apartment";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import MailIcon from "@mui/icons-material/Mail";
import FolderIcon from "@mui/icons-material/Folder";
import ReceiptIcon from "@mui/icons-material/Receipt";
import PeopleIcon from "@mui/icons-material/People";
import BusinessIcon from "@mui/icons-material/Business";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import GroupsIcon from "@mui/icons-material/Groups";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import NoteIcon from "@mui/icons-material/Note";

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
			<MenuItem
				active={selected === title}
				style={{
					color: colors.primary[10],
					marginBottom: "10px",
				}}
				onClick={() => setSelected(title)}
				icon={icon}
			>
				<Typography sx={{ fontSize: { xs: "14px", md: "16px" } }} fontWeight={600}>
					{title}
				</Typography>
				<Link to={to} />
			</MenuItem>
		);
};

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [selected, setSelected] = useState("Dashboard");
  const isAdmin = useSelector((state) => state.isAdmin)

  return (
			<Box
				sx={{
					position: "sticky",
					// display: "flex",
					// height: "100vh",
					// top: 72,
					// bottom: 0,
					// zIndex: 10000,
					"& .pro-sidebar-inner": {
						background: `${colors.primary[10]} !important`,
					},
					"& .pro-icon-wrapper": {
						backgroundColor: "transparent !important",
					},
					"& .pro-inner-item": {
						padding: { xs: "5px 15px 5px 10px !important", sm: "5px 35px 5px 20px !important" },
					},
					"& .pro-menu-item": {
						color: "#333 !important",
					},
					"& .pro-inner-item:hover": {
						color: "#FCB200 !important",
					},
					"& .pro-menu-item.active": {
						color: "#FCB200 !important",
					},
					borderRight: "1px solid #dfdede;",
				}}
			>
				<ProSidebar collapsed={isCollapsed}>
					<Menu iconShape="square">
						{/* LOGO AND MENU ICON */}
						<MenuItem
							onClick={() => setIsCollapsed(!isCollapsed)}
							icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
							style={{
								margin: "10px 0 20px 0",
								color: colors.grey[10],
							}}
						>
							{!isCollapsed && (
								<Box display="flex" justifyContent="space-between" alignItems="center" ml="15px">
									<Typography variant="h4" color={colors.grey[10]}>
										ADMIN
									</Typography>
									<IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
										<MenuOutlinedIcon sx={{ color: colors.grey[10] }} />
									</IconButton>
								</Box>
							)}
						</MenuItem>
                        
                        {/* {isAdmin} */}
						<Box paddingLeft={isCollapsed ? undefined : "1%"}>
							<Item title="Dashboard" to="/home" icon={<HomeOutlinedIcon />} selected={selected} setSelected={setSelected} />
                            <Item title="Loan Requests" to="/tenants-loans" icon={<FolderIcon />} selected={selected} setSelected={setSelected} />
							<Item title="Users" to="/tenants" icon={<FolderIcon />} selected={selected} setSelected={setSelected} />
							<Item title="Brokers" to="/brokers" icon={<GroupsIcon />} selected={selected} setSelected={setSelected} />
							<Item title="Landlords" to="/landlords" icon={<BusinessIcon />} selected={selected} setSelected={setSelected} />
                            <Item title="Landlord Expenses" to="/landlord-expenses" icon={<AttachMoneyIcon />} selected={selected} setSelected={setSelected} />
							<Item title="Payments" to="/payments" icon={<ReceiptIcon />} selected={selected} setSelected={setSelected} />
							<Item title="Properties" to="/properties" icon={<HomeWorkIcon />} selected={selected} setSelected={setSelected} />
                            <Item title="Active Schedules" to="/schedules" icon={<PeopleIcon />} selected={selected} setSelected={setSelected} />
							<Item title="Bookings" to="/bookings" icon={<HomeWorkIcon />} selected={selected} setSelected={setSelected} />
                            <Item title="Managers" to="/managers" icon={<SupervisorAccountIcon />} selected={selected} setSelected={setSelected} />
                            <Item title="Properties Managed" to="/properties-managed" icon={<ManageAccountsIcon />} selected={selected} setSelected={setSelected} />
							<Item title="Settings" to="/settings" icon={<SettingsIcon />} selected={selected} setSelected={setSelected} />
						</Box>
					</Menu>
				</ProSidebar>
			</Box>
		);
};

export default Sidebar;