import * as React from "react";
import axios from "axios";
import { baseURL } from "services/API";
import { Grid, Box } from "@mui/material";
import PropertiesManagedTable from "components/Tables/PropertiesManagedTable";
import DummyTable from "components/Tables/DummyTable";
import FormDialog from "components/modal";
import { useRentals, useAttachedProperty } from "services/hooks/rentals";
import RentalsForm from "components/Forms/RentalsForm";
import PropertyPopover from "components/PopOvers/PropertyPopover";
import ProgressScale from "components/ProgressScale";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setRefreshCount } from "state";
import TopSnack from "components/TopSnack";


const PropertiesManaged = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const landlordId = useSelector((state) => state.landlord);
  const { rentals, isLoadingRentals } = useRentals();

  // Assuming you have a 'manager' object from props or state
  const manager = { id: 1 }; // Example manager object
  const {attachedProperties, isLoadingAttachedProperties, errorAttachedProperties} = useAttachedProperty(manager.id);

  const [rental, setRental] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [modal, setModal] = React.useState("");
  const [details, setDetails] = React.useState("");
  const [rentalName, setRentalName] = React.useState("");
  const [rentalType, setRentalType] = React.useState("");
  const [rentalLocation, setRentalLocation] = React.useState("");
  const [rentalCity, setRentalCity] = React.useState("");
  const [security, setSecurity] = React.useState(false);
  const [activeRental, setActiveRental] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openPopper = Boolean(anchorEl);
  const popperId = openPopper ? "simple-popover" : undefined;
  const [openSnack, setOpenSnack] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const [severity, setSeverity] = React.useState("");
  const [message, setMessage] = React.useState("");

  const handleRentalNameChange = (event) => {
    setRentalName(event.target.value);
  };

  const handleChangeRentalType = (event) => {
    setRentalType(event.target.value);
  };

  const handleRentalLocationChange = (event) => {
    setRentalLocation(event.target.value);
  };

  const handleRentalCityChange = (event) => {
    setRentalCity(event.target.value);
  };

  const handleSecurityCheck = (event) => {
    setSecurity(event.target.checked);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setModal("");
    setOpen(false);
  };

  const handleClickPopOver = (event, row) => {
    setAnchorEl(event.currentTarget);
    setActiveRental(row);
  };

  const handleClosePopOver = () => {
    setAnchorEl(null);
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack({ ...openSnack, open: false });
  };

  const handleModalView = (modalView) => {
    setModal(modalView);
  };

  const handleRentalFormSubmit = async () => {
    try {
      console.log(landlordId, rentalName, rentalType, rentalLocation);
      const response = await axios.post(`${baseURL}/landlords/rentals`, {
        related_landlord: landlordId,
        rental_name: rentalName,
        rental_type: rentalType,
        location: rentalLocation,
      });
      console.log(response.data);

      setOpenSnack({ ...openSnack, open: true });
      setSeverity("success");
      setMessage("Property Added Successfully");
      setOpen(false);
      dispatch(setRefreshCount());
    } catch (err) {
      setOpenSnack({ ...openSnack, open: true });
      setSeverity("error");
      setMessage("Property Addition failed, Please check property details");
    }
  };

  const handleMenuOpen = (event) => {
    // Your logic here
  };

  return (
    <Box m="20px">
      <Grid container spacing={2}>
        <Grid item xs={12} spacing={2}>
          {isLoadingRentals && <ProgressScale />}
          <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
            <h1 className="pageHeader">Properties Managed</h1>
          </div>

          <Grid container spacing={2}>
            <Grid item xs={12} sx={{ marginTop: 3 }}>
              {isLoadingRentals ? (
                <DummyTable />
              ) : (
                <PropertiesManagedTable
                  handleMenuOpen={handleMenuOpen}
                  attachedProperties={attachedProperties}
                  isLoadingAttachedProperties={isLoadingAttachedProperties}
                  errorAttachedProperties={errorAttachedProperties}
                />
              )}
            </Grid>
          </Grid>
          <PropertyPopover
            popperId={popperId}
            openPopper={openPopper}
            anchorEl={anchorEl}
            handleClosePopOver={handleClosePopOver}
            handleClickOpen={handleClickOpen}
            navigate={navigate}
            activeRental={activeRental}
            handleModalView={handleModalView}
          />

          <FormDialog
            open={open}
            handleClose={handleClose}
            enableActions={true}
            content={
              <RentalsForm
                rentalType={rentalType}
                rentalCity={rentalCity}
                handleRentalNameChange={handleRentalNameChange}
                handleRentalCityChange={handleRentalCityChange}
                handleRentalLocationChange={handleRentalLocationChange}
                handleSecurityCheck={handleSecurityCheck}
                handleChangeRentalType={handleChangeRentalType}
              />
            }
            handleRentalFormSubmit={handleRentalFormSubmit}
          />
        </Grid>
      </Grid>
      <TopSnack
        open={openSnack.open}
        vertical={openSnack.vertical}
        horizontal={openSnack.horizontal}
        handleCloseSnack={handleCloseSnack}
        severity={severity}
        message={message}
      />
    </Box>
  );
};

export default PropertiesManaged;
