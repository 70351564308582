import React from 'react';
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableFooter,
  TableCell,
  Typography,
  TableBody,
  IconButton,
  Menu,
  MenuItem,
  TablePagination,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

// Reuse StyledTableCell and StyledTableRow
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#FEF0DE",
    color: "#000",
    fontSize: 16,
    fontWeight: 700,
    border: "none",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    border: "none",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#f9f9f9",
  },
  "&:hover": {
    backgroundColor: "#f1f1f1",
  },
  "&:last-child td, &:last-child th": {
    border: "none",
  },
}));

// Custom pagination actions component
const TablePaginationActions = (props) => {
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div style={{ flexShrink: 0, marginLeft: 'auto' }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </div>
  );
};

// ExpensesTable component
const ExpensesTable = ({ data, page, rowsPerPage, onPageChange, handleMenuClick, handleMenuClose, anchorEl }) => {
  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>Landlord Name</StyledTableCell>
              <StyledTableCell>Business Name</StyledTableCell>
              <StyledTableCell>Rental Name</StyledTableCell>
              <StyledTableCell>Description</StyledTableCell>
              <StyledTableCell>Amount</StyledTableCell>
              <StyledTableCell>Date</StyledTableCell>
              <StyledTableCell>Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((landlordExpense) => (
              <StyledTableRow key={landlordExpense.id}>
                <StyledTableCell>
                  {landlordExpense.related_landlord_rental.related_landlord.related_user.first_name} {landlordExpense.related_landlord_rental.related_landlord.related_user.last_name}
                </StyledTableCell>
                <StyledTableCell>
                  {landlordExpense.related_landlord_rental.related_landlord.business_name}
                </StyledTableCell>
                <StyledTableCell>
                  {landlordExpense.related_landlord_rental.rental_name}
                </StyledTableCell>
                <StyledTableCell>{landlordExpense.description}</StyledTableCell>
                <StyledTableCell>{landlordExpense.amount}</StyledTableCell>
                <StyledTableCell>{landlordExpense.created_on}</StyledTableCell>
                <StyledTableCell>
                  <IconButton
                    size="small"
                    onClick={(event) => handleMenuClick(event, landlordExpense)}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                  >
                    <MenuItem onClick={() => handleMenuClose('edit')}>
                      Edit
                    </MenuItem>
                    <MenuItem onClick={() => handleMenuClose('sendMessage')}>
                      Send Message
                    </MenuItem>
                    <MenuItem onClick={() => handleMenuClose('delete')}>
                      Delete
                    </MenuItem>
                  </Menu>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={onPageChange}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
};

export default ExpensesTable;
