import React, { useState, useEffect } from "react";
import axios from "axios";
import FormDialog from "components/modal";
import { baseURL } from "services/API";
import {Box,Button,Typography,Grid,styled,} from "@mui/material";
import LandlordTenantPaymentsTable from "components/Tables/LandlordTenantPaymentsTable";
import LandlordTenantPaymentsForm from "components/Forms/LandlordTenantPaymentsForm"
import { useTenants, useRentalUnits, useMonthsAndYears } from "services/hooks/rentals";
import { useSelector } from "react-redux";

// Style definition for the title container
const TitleContainer = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

const LandlordTenantsPayments = () => {
  const [page, setPage] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [newPayment, setNewPayment] = useState({
    related_rental_unit: "",
    related_tenant: "",
    amount: "",
    related_month: "",
    payment_method: "",
    date_paid: "",
  });
  const [isSubmittingData, setIsSubmittingData] = useState(false);
  const [tenantOptions, setTenantOptions] = useState([]);
  const [rentalUnitOptions, setRentalUnitOptions] = useState([]);
  const [monthOptions, setMonthOptions] = useState([]);

  const landlord = useSelector((state) => state.landloard);
  const { tenants, loading: loadingTenants } = useTenants(landlord.id);
  const { rentalUnits, isLoadingRentalUnits } = useRentalUnits(landlord.id);
  const { monthsAndYears, isLoadingMonthsAndYears } = useMonthsAndYears(landlord.id);

  const rowsPerPage = 5;

  // Handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewPayment((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Handle fetching and setting dropdown data
  useEffect(() => {
    if (tenants) {
      setTenantOptions(
        tenants.map((tenant) => ({
          value: tenant.id,
          label: `${tenant.related_tenant.first_name} ${tenant.related_tenant.last_name}`,
        }))
      );
    }
  }, [tenants]);

  useEffect(() => {
    if (rentalUnits) {
      setRentalUnitOptions(
        rentalUnits.map((unit) => ({
          value: unit.id,
          label: unit.related_rental_unit.unit_name,
        }))
      );
    }
  }, [rentalUnits]);

  useEffect(() => {
    if (monthsAndYears) {
      setMonthOptions(
        monthsAndYears.map((monthYear) => ({
          value: monthYear.id,
          label: `${monthYear.month} ${monthYear.year}`,
        }))
      );
    }
  }, [monthsAndYears]);

  const handleAddPayment = () => {
    setIsSubmittingData(true);

    const paymentData = {
      ...newPayment,
      amount: parseFloat(newPayment.amount),
      is_payment_checkout: true,
      status: true,
    };

    axios
      .post(`${baseURL}/tenants/payments/custom`, paymentData)
      .then((response) => {
        console.log("Payment added successfully", response.data);
        handleCloseDialog();
      })
      .catch((error) => {
        console.error("Error adding payment", error);
      })
      .finally(() => {
        setIsSubmittingData(false);
      });
  };

  // Dummy data for the table
 // Dummy data for the table
const paymentData = [
  {
    tenant: "John Doe",            
    landlordName: "James Landlord",       
    businessName: "ABC Rentals",          
    rentalName: "Unit 101",          
    relatedMonth: "August 2023",    
    datePaid: "2023-08-15",          
    amount: 500,                     
  },
  {
    tenant: "Jane Smith",             
    landlordName: "Sarah Landlord",       
    businessName: "XYZ Properties",      
    rentalName: "Unit 202",          
    relatedMonth: "July 2023",       
    datePaid: "2023-07-20",          
    amount: 750,                      
  },
];


  return (
    <Box m="20px">
      <Grid container spacing={2} style={{ marginTop: "20px" }}>
        <Grid item xs={12}>
          <TitleContainer>
            <Typography
              variant="h5"
              style={{ marginBottom: 0, fontWeight: "bold", fontSize: "25px", paddingLeft: 5 }}
            >
              Tenant Payments
            </Typography>
            <Button variant="contained" color="primary" onClick={handleOpenDialog}>
              Add Payment
            </Button>
          </TitleContainer>
        </Grid>
      </Grid>

      <Box>
        <LandlordTenantPaymentsTable
          page={page}
          rowsPerPage={rowsPerPage}
          handleChangePage={handleChangePage}
          paymentData={paymentData} // Using dummy data
        />
      </Box>

      <FormDialog
        open={openDialog}
        title="Add Payment"
        text="Fill out the details below to add a new payment."
        content={
          <LandlordTenantPaymentsForm tenantOptions = {tenantOptions} rentalUnitOptions = {rentalUnitOptions} monthOptions = {monthOptions}
          isLoadingMonthsAndYears ={isLoadingMonthsAndYears} newPayment = {newPayment} handleInputChange = {handleInputChange} />
        }
        handleClose={handleCloseDialog}
        handleSubmit={handleAddPayment}
        isSubmittingData={isSubmittingData}
      />
    </Box>
  );
};

export default LandlordTenantsPayments;
