import React from "react";
import { TextField, DialogContent } from "@mui/material";

const AddBrokerForm = ({ newBroker, handleChange }) => {
  return (
    <DialogContent>
      <TextField
        autoFocus
        margin="dense"
        label="Name"
        type="text"
        fullWidth
        name="broker_name"
        value={newBroker.broker_name}
        onChange={handleChange}
      />
      <TextField
        autoFocus
        margin="dense"
        label="National Identification Number(NIN)"
        type="text"
        fullWidth
        name="broker_NIN"
        value={newBroker.broker_name}
        onChange={handleChange}
      />
      <TextField
        margin="dense"
        label="Location"
        type="text"
        fullWidth
        name="broker_location"
        value={newBroker.broker_location}
        onChange={handleChange}
      />
      <TextField
        margin="dense"
        label="Contact"
        type="text"
        fullWidth
        name="broker_contact"
        value={newBroker.broker_contact}
        onChange={handleChange}
      />
    </DialogContent>
  );
};

export default AddBrokerForm;
